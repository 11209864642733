import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="home">
      <div className="copy">
        <h2>My story</h2>

        <p>
          As a creative at heart, I discovered my passion for web design at a
          young age while creating the online presence for my music projects. My
          interest in design and technology was sparked by using tools like
          Photoshop and customizing Myspace pages using HTML and CSS.
        </p>
        <p>
          Despite my love for design and development, I initially pursued a
          career in social services for five years. However, my passion for web
          design never faded, and I eventually taught myself HTML, CSS, and
          JavaScript. I put my skills to use by building my first{" "}
          <a
            href="https://www.linesbydevon.com"
            target="_blank"
            rel="noreferrer"
          >
            WordPress site
          </a>
          , showcasing my creative and professional journey.
        </p>
        <p>
          My dedication and drive caught the attention of EAB, who offered me my
          first job as a web designer. Over the next four years, I honed my
          skills as a self-taught web designer, working on a variety of projects
          and continuously improving my craft.
        </p>
      </div>
      <div className="quoteContainer">
        <figure>
          <blockquote>
            <p>
              Devon has effectively replaced two senior web designers and
              carried the full team workload while also mentoring our new
              graphic designer. In addition to that, he has improved the quality
              and timeliness of our email product. His proactivity in monitoring
              this channel has again never been seen in my tenure here.
            </p>
            <p>
              I&rsquo;m not even sure how to express the level of collaboration
              Devon brings to the group. He&rsquo;s continually refining process
              for everyone&rsquo;s benefit and this is echoed in his
              team&rsquo;s feedback of what he brings to the team from his
              flexible attitude, his impressive capacity, and his delightful
              disposition.
            </p>
          </blockquote>
          <div className="captionContainer">
            <figcaption>
              <span>Synthia Bialkowski</span>
              <br />
              EAB Creative Supervisor
            </figcaption>
          </div>
        </figure>
      </div>
      <div className="copy">
        <p>
          In 2022, I took the next step in my career and enrolled in a software
          engineering bootcamp at General Assembly. Through 500+ hours of
          expert-led instruction and hands-on learning, I developed an even
          deeper understanding of the industry and its most in-demand
          technologies.
        </p>
        <p>
          I'm thrilled to continue growing as a software engineer and grateful
          for the endless opportunities to learn, solve problems, be creative,
          and collaborate with talented developers. I invite you to explore my
          recent <Link to="/projects">projects</Link> and{" "}
          <Link to="/resume">resume</Link> to learn more about my journey in
          tech.
        </p>
      </div>
    </div>
  );
}
