import Collapsible from "./Resume/Collapsible";
import Button from "../Button";
import DevonJonesResume from "../../assets/DevonJonesResume.pdf";
import {Link} from 'react-router-dom';
export default function Resume() {
  return (
    <div id="meat">
      <div className="downloadResume">
        <h2>Want something<br/>printer-friendly?</h2>
        <Button url={DevonJonesResume} download={true}>Download Resume PDF</Button>
      </div>
      <section id="workExp">
        <h2>Relevant Experience</h2>
        <Collapsible
          employer="General Assembly"
          role="Software Engineering Student"
          startDate="October 2022"
          endDate="January 2023"
        >
          <p style={{margin: 0}} className="copy">Completed a rigorous 500+ hour software engineering bootcamp, receiving expert-led instruction and hands-on experience in web development fundamentals and the most in-demand technologies. Demonstrated proficiency by developing various <Link to="/projects">projects</Link>.</p>
        </Collapsible>
        <Collapsible
          employer="Capital One"
          role="Web Designer"
          startDate="April 2020"
          endDate="October 2022"
        >
          <li>
          Designed and optimized assets for deployment in external emails using Sketch and Adobe software.
          </li>
          <li>
          Created compelling email designs through the use of custom HTML and CSS, with code snippets to enhance functionality.
          </li>
          <li>
          Worked closely with project managers, copywriters, and internal partners to formulate strategy, overcome challenges, and deliver high-quality products within tight deadlines.
          </li>
          <li>
          Conducted technical reviews of peers' code to ensure compliance with internal brand, technical, and accessibility standards for email development.
          </li>
        </Collapsible>
        <Collapsible
          employer="EAB Advancement Marketing Services"
          role="Web Designer"
          startDate="March 2019"
          endDate="April 2020"
        >
          <li>
          Progressed from Junior Web Designer to Web Designer due to exceptional performance.
          </li>
          <li>
          Designed visually appealing layouts and assets for emails, landing pages, and social media display advertisements using Adobe software.
          </li>
          <li>
          Applied expertise in conversion-centered design, HTML, and SCSS to develop brand-compliant, responsive, and accessible web pages and emails.
          </li>
          <li>
          Partnered with copywriters, print designers, and digital marketing specialists to create cohesive and effective campaigns.
          </li>
        </Collapsible>
        <Collapsible
          employer="Richmond Area Skateboard Alliance (RASA)"
          role="Web Admin (Volunteer)"
          startDate="September 2019"
          endDate="April 2020"
        >
          <li>
          Served as needed to ensure content on organization's WordPress was up-to-date, relevant, and navigable.
          </li>
          <li>
          Designed and curated assets for use in print pieces and social media posts on Facebook and Instagram.
          </li>
          <li>
          Coordinated with organization administration and digital media volunteers to strategize messaging in order to encourage audience engagement and donations.
          </li>
          <li>
          Developed the assets and messaging for the organization's first Giving Tuesday campaign. This campaign included print fliers in addition to social media posts on Facebook and Instagram.
          </li>
        </Collapsible>
      </section>
  
    </div>
  );
}
